import React, { useRef } from "react";
import axios from "axios";

function App() {
  const inputRef = useRef<HTMLInputElement>(null);
  const handleFileChange = async () => {
    const secretKey = "gfdgsdfawd"; // Replace with your secret key
    const apiUrl = "https://images.lagzya.top/up.php"; // Replace with your actual API endpoint
    if (
      inputRef === null ||
      inputRef.current === undefined ||
      !inputRef?.current?.files
    )
      return;
    // Create a FormData object to send the file
    const formData = new FormData();
    formData.append("secret", secretKey);
    formData.append("sharex", inputRef.current.files[0]);

    try {
      const response = await axios.post(apiUrl, formData);

      // Handle the response from the server
      console.log("File upload successful:", response.data);
      window.location.href = response.data;
    } catch (error: any) {
      // Handle errors
      console.error("File upload failed:", error.message);
    }
  };
  return (
    <div className="flex justify-center content-center items-center min-h-screen min-w-full">
      <div className={"flex flex-col justify-center content-center gap-2"}>
        <input
          ref={inputRef}
          className={"bg-sky-600 rounded-full"}
          type={"file"}
          onChange={handleFileChange}
        />
      </div>
    </div>
  );
}

export default App;
